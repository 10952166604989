
import React from 'react';
import { Link, Route } from 'react-router-dom';
import moment from 'moment';

import './App.css';

import { Loader, fetch, FormDashline, FormInputText, FormButtons, FormInputSelect,
  FormInputContainer, Modal , FormInputDate  } from './Common.js';
    
import  { Breadcumbs, can, AbstractTableViewer,
    AgentSelect, FileViewer , FileUploadInput, apiFormInit, apiFormSave, AbstractApiFormFooter, LocalCache } from './AppCommon';

import { NewCallModal, CallsDetailView } from './Calls';
import { NewAppointmentModal, AppointmentDetailView, AppointmentOutcome } from './Appointments';
import { NewContractModal, ContractDetailView } from './Contracts';
import { UserSelect } from './Users';

import { LeadInterestSelect, LeadInterestsPlainViewer } from './LeadInterests';


import {ContractStateSelect, ContractStateViewer} from './ContractStates';
import {NewPracticeModal} from "./Practices";
import {EnelxSellModal} from "./EnelxSells";
import {showExportModal} from "./Exports";

class AddressData extends React.Component{
  constructor(props){
    super(props);

    let o = {};

    const prefix = this.props.prefix || '';

    this.fields = [ 
      prefix + 'address' , 
      prefix + 'civicNumber' , 
      prefix + 'stairs',
      prefix + 'intern',
      prefix + 'city',
      prefix + 'province',
      prefix + 'cap',
     ];

    Object.keys( this.props.values ).map( k => {
      if( this.fields.indexOf( k ) == -1 )
        return;

      o[k] = this.props.values[k];
    });

    this.state = o;
  }

  componentDidUpdate( prevProps , prevState ){

    const prefix = this.props.prefix || '';

    if( JSON.stringify( prevState ) != JSON.stringify( this.state ) ){
      this.props.onChange( this.state );
    }

    if( JSON.stringify( prevProps.values ) != JSON.stringify( this.props.values ) ){
      let o = {};
      Object.keys( this.props.values ).map( k => {
        if( this.fields.indexOf( k ) == -1 )
          return;
  
        o[k] = this.props.values[k];
      });
  
      this.state = o;
    }

  }

  render(){
    const prefix = this.props.prefix || "";

    return <React.Fragment>
      <div className="row">
        <div className="col-sm-12">
          <FormInputText label="Indirizzo" value={this.state[prefix+"address"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"address"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"address"]: se.target.value})} />
        </div>
        <div className="col-sm-3">
          <FormInputText label="Civico" value={this.state[prefix+"civicNumber"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"civicNumber"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"civicNumber"]: se.target.value})} />
        </div>
        <div className="col-sm-3">
          <FormInputText label="Scala" value={this.state[prefix+"stairs"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"stairs"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"stairs"]: se.target.value})} />
        </div>
        <div className="col-sm-3">
          <FormInputText label="Interno" value={this.state[prefix+"intern"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"intern"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"intern"]: se.target.value})} />
        </div>
        <div className="col-sm-6">
          <FormInputText label="Città" value={this.state[prefix+"city"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"city"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"city"]: se.target.value})} />
        </div>
        <div className="col-sm-3">
          <FormInputText label="Provincia" value={this.state[prefix+"province"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"province"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"province"]: se.target.value})} />
        </div>
        <div className="col-sm-3">
          <FormInputText label="CAP" value={this.state[prefix+"cap"]}
            disabled={this.props.disabled}
            validation={this.state.validation} name={prefix+"cap"}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({[prefix+"cap"]: se.target.value})} />
        </div>
      </div>
    </React.Fragment>
  }
}

class LeadChangeStateModal extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      id: this.props.id
    };

    apiFormInit( 'Leads' , this );

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    fetch( 'api', 'Leads' , 'get' , this.props.id ).then( msg => {
      this.setState({ContractState: msg.body.ContractState});
    })
  }

  onSubmit(se){
    se.preventDefault();

    this.setState({apiFormSaving: true});
    fetch( 'api' , 'Leads' , 'changeState' , this.state ).then( res => {
      this.setState({apiFormSaving: false});
      if( this.props.onDone )
        this.props.onDone( res );
    });

  }

  render(){
    return <Modal onClose={this.props.onDone}>
        <form onSubmit={this.onSubmit}>

          <LeadStateSelect label="Nuovo stato" value={this.state.ContractState}
                           isMulti={false}
                           onChange={se=>this.setState({ContractState: se})} />

        <FormButtons saveLabel="Salva stato"  direction={FormInputContainer.HORIZONTAL} 
            onSave={this.onSubmit} 
            saving={this.state.apiFormSaving}
            onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
        </form>
      </Modal>;
  }
}

class NewLeadModal extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {};

      apiFormInit( 'Leads' , this );

      this.onSubmit = this.onSubmit.bind(this);
    }
  
    onSubmit(se){
      se.preventDefault();
      apiFormSave( 'Leads' , this );
    }
  
    render(){

      return <Modal onClose={this.props.onDone}>
        <form onSubmit={this.onSubmit}>
  
          <h3 className="m-t-none m-b">Inserimento Lead</h3>
  
          {/* <ChannelSelect value={this.state.ChannelId}
              validation={this.state.validation} name="ChannelId"
              onChange={se=>this.setState({ChannelId: se})} /> */}
  
          <div className="row">
            <div className="col-sm-6">
              <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                requiredField={true}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />
            </div>
            <div className="col-sm-6">
              <FormInputText label="Cognome" value={this.state.surname}
                direction={FormInputContainer.HORIZONTAL}
                requiredField={true}
                validation={this.state.validation} name="surname"
                onChange={se=>this.setState({surname: se.target.value})} />
            </div>
            <div className="col-sm-12">
              <FormInputText label="Ragione sociale" value={this.state.businessName}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="businessName"
                onChange={se=>this.setState({businessName: se.target.value})} />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText label="Indirizzo" value={this.state.address}
                validation={this.state.validation} name="address"
                requiredField={true}
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>this.setState({address: se.target.value})} />
            </div>
            <div className="col-sm-3">
              <FormInputText label="Civico" value={this.state.civicNumber}
                validation={this.state.validation} name="civicNumber"
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>this.setState({civicNumber: se.target.value})} />
            </div>
            <div className="col-sm-3">
              <FormInputText label="CAP" value={this.state.cap}
                validation={this.state.validation} name="cap"
                direction={FormInputContainer.HORIZONTAL} requiredField={true}
                onChange={se=>this.setState({cap: se.target.value})} />
            </div>
          </div>
          
          <div className="row">
              <div className="col-sm-12">
                <FormInputText label="Codice Fiscale / P.IVA" value={this.state.fiscalCode}
                    validation={this.state.validation} name="fiscalCode" requiredField={true}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setState({fiscalCode: se.target.value})} />
              </div>
          </div>

          <div className="row">
              <div className="col-sm-6">
                <FormInputText label="Provincia" value={this.state.province}
                    validation={this.state.validation} name="province"
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setState({province: se.target.value})} />
              </div>
              <div className="col-sm-6">
                <FormInputText label="Città" value={this.state.city}
                    direction={FormInputContainer.HORIZONTAL}
                    requiredField={true}
                    validation={this.state.validation} name="city"
                    onChange={se=>this.setState({city: se.target.value})} />
              </div>
          </div>

          <div className="row">
              <div className="col-sm-6">
                  <FormInputText label="Telefono" value={this.state.telephone}
                    direction={FormInputContainer.HORIZONTAL}
                    validation={this.state.validation} name="telephone"
                    requiredField={true}
                    onChange={se=>this.setState({telephone: se.target.value})} />
              </div>
              <div className="col-sm-6">
                <FormInputText label="Telefono Secondario" value={this.state.telephoneSec}
                      direction={FormInputContainer.HORIZONTAL}
                      validation={this.state.validation} name="telephoneSec"
                      onChange={se=>this.setState({telephoneSec: se.target.value})} />
              </div>
          </div>

          <LeadInterestSelect value={this.state.Interests}
            onChange={se=>{this.setState({Interests: se})}}
            direction={FormInputContainer.HORIZONTAL} />

          <FormInputText label="Email" value={this.state.email}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="email"
              onChange={se=>this.setState({email: se.target.value})} />
  
          <FormInputText label="Note" value={this.state.note}
            validation={this.state.validation} name="note"
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({note: se.target.value})} />
  
          { can('Leads.assignLeads') && <AgentSelect value={this.state.TakenBy}
            label="Assegna ad agente"
            validation={this.state.validation} name="TakenByUserId"
            onChange={se=>this.setState({TakenBy: se, TakenByUserId: se ? se.id : null})} />  }

          <AbstractApiFormFooter {...this.state} />

          <FormDashline />
  
          <label>Allegati</label>
          <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
            filesId={this.state.filesId} />
  
          <FormButtons saveLabel="Salva Lead"  direction={FormInputContainer.HORIZONTAL} 
            onSave={this.onSubmit} 
            saving={this.state.apiFormSaving}
            onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
        </form>
      </Modal>;
    }
  }

class LeadStateSelect extends React.Component {
  render() {
    return <ContractStateSelect value={this.props.value}
                                parentEntityType={"Leads"}
                                direction={FormInputContainer.HORIZONTAL}
                                label={"Stato lead"}
                                isMulti={typeof(this.props.isMulti) !== 'undefined' ? this.props.isMulti : true}
                                onChange={this.props.onChange}/>
  }
}

class LeadState extends React.Component {
    render() {

      if( !this.props.lead.ContractState )
        return '-';

      return <ContractStateViewer contract={{
        state: this.props.lead.ContractState.name,
        ContractState: {
          type: this.props.lead.ContractState.type
        }
      }}/>
    }
}

class LeadDetail extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        loading: true,
        id: undefined,
        newCallModal: false,
        newAppointmentModal: false,
        appointmentOutcomeModal: false,
        newContractModal: false,
        newSellModal: false,
        changeStateModel: false,
      };
  
      this.newCall = this.newCall.bind(this);
      this.newAppointment = this.newAppointment.bind(this);
      this.newContract = this.newContract.bind(this);
      this.newPractice = this.newPractice.bind(this);
      this.newSell = this.newSell.bind(this);
      this.onTake = this.onTake.bind(this);
      this.onNeverAnswer = this.onNeverAnswer.bind(this);

      this.reloadData();
    }
    
    componentDidUpdate( prevProps , prevState ){
      if( prevState.id != this.state.id || prevProps.id != this.props.id ){
        this.reloadData();
      }
    }
  
    reloadData(){
      this.setState({loading: true, 
        newCallModal: false,
        newContractModal: false,
        newPracticeModal: false,
        newSellModal: false,
        newAppointmentModal: false,
        appointmentOutcomeModal: false
      });
  
      fetch( 'api' , 'Leads' , 'get' , this.props.id ).then( data => {
        this.setState({...data.body,loading: false });
      });
    }

    onNeverAnswer(se){
      fetch( 'api' , 'Leads' , 'neverAnswer' , {id: this.state.id} ).then( data => {
        //window.location.reload();
        this.reloadData();
      });
    }

    onTake( se ){

      // TakenByUserId a true, verra impostato l id utente loggato
      fetch( 'api' , 'Leads' , 'take' , {id: this.state.id, TakenByUserId: true} ).then( data => {
        window.location.href = window.location.pathname + '/detail/'+ this.state.id
        //this.reloadData();
      });
    }
  
    newCall( se ){
      se.preventDefault();
      this.setState({newCallModal: true});
    }
  
    newAppointment( se ){
      se.preventDefault();
      this.setState({newAppointmentModal: true});
    }
  
    newContract( se ){
      se.preventDefault();
      this.setState({ newContractModal: true });
    }
    newPractice( se ){
      se.preventDefault();
      this.setState({ newPracticeModal: true });
    }
    newSell( se ){
      se.preventDefault();
      this.setState({ newSellModal: true });
    }
  
    render(){
  
      if( this.state.loading )
        return <Loader />;
  
      return <div className="row m-b-lg">

          {this.state.changeStateModel && <LeadChangeStateModal 
            onDone={se=>{this.setState({changeStateModel: false});this.reloadData()}}
            id={this.state.changeStateModel} />}

          <div className="col-lg-12 text-center">
              <h2>{formatLeadName( this.state )} </h2>
              <p className="text-center">{/*this.state.channel.brand.name*/} {/*this.state.channel.name*/}</p>
          </div>
          <div className="col-lg-12">

            <p className="text-left"><strong>Anagrafica</strong></p>

            <p>{this.state.address} {this.state.civicNumber}</p>
            <p>{this.state.city}</p>
            <p>{this.state.telephone} {this.state.telephoneSec}</p>

              <p className="text-left"><strong>Note</strong></p>
  
              <p>{this.state.note}</p>
  
              <p className="text-left"><strong>Origine</strong></p>
  
              <p>{this.state.origin}</p>
  
              <p className="text-left"><strong>Stato</strong></p>

              <LeadState lead={this.state} style={{
                  padding: '10px', marginBottom: '20px', display: 'block', textAlign: 'center'}} /> 

              {can('Leads.changeState') && <button className="btn"
                onClick={se=>{this.setState({changeStateModel: this.state.id})}}>Cambia stato</button> }
  
              {this.state.Files && this.state.Files.length > 0 && [
                <p className="text-left"><strong>Allegati</strong></p>,
                this.state.Files.map( file => { return <div> 
                  <FileViewer id={file.id} /> </div> 
                } )
              ]}
  
              <p className="text-left" style={{marginTop: '20px'}}><strong> Azioni lead</strong></p>
  
              {((this.state.TakenByUserId == LocalCache.get('loginData').User.id 
                /*&& this.state.state != 'Mai risposto'*/) || can('Leads.overrideLeadAction')) && <React.Fragment>
                <a data-toggle="modal" className="btn btn-primary btn-sm btn-block" 
                  href="#" onClick={this.newCall}>Nuova Chiamata</a>

                  <a data-toggle="modal" className="btn btn-primary btn-sm btn-block"
                     href="#" onClick={this.newSell}>Nuova Vendita</a>
    
                <a data-toggle="modal" className="btn btn-primary btn-sm btn-block" 
                  href="#" onClick={this.newAppointment}>Nuovo Appuntamento</a>


                {this.state.Appointments && this.state.Appointments.length > 0 && <a data-toggle="modal" className="btn btn-primary btn-sm btn-block"
                  href="#" onClick={se=>{
                    se.preventDefault();
                    this.setState({appointmentOutcomeModal: this.state.Appointments[0].id})
                }}>Esita Appuntamento</a> }
    
                <a data-toggle="modal" className="btn btn-primary btn-sm btn-block" 
                href="#" onClick={this.newContract}>Nuovo Contratto</a>

                <a data-toggle="modal" className="btn btn-primary btn-sm btn-block"
                href="#" onClick={this.newPractice}>Nuova Pratica</a>

                <a data-toggle="modal" className="btn btn-danger btn-sm btn-block" 
                  href="#" onClick={this.onNeverAnswer}>Passa a stato "Mai risposto"</a>
              </React.Fragment>}
  
              {(!this.state.TakenByUserId && can('Leads.take')) && <button type="button" className="btn btn-primary btn-sm btn-block" onClick={this.onTake}>
                Prendi in carico
              </button> }

              { ((this.state.TakenByUserId == LocalCache.get('loginData').User.id || can('Contracts.viewAlways')) && this.state.Contracts && this.state.Contracts.length>0 ) && <React.Fragment>
                <p className="text-left" style={{marginTop: '20px'}}><strong>Contratti / Pratiche</strong></p>
                {this.state.Contracts.map( contract => {

                    if(contract.type==='contract') {
                        return <a data-toggle="modal" className="btn btn-primary btn-sm btn-block"
                                  href="#" onClick={se => {
                            this.setState({newContractModal: contract.id});
                        }}>Vedi Contratto {contract.uniqueUuid}</a>
                    }else{

                        let extra = {};
                        try {
                            extra = JSON.parse(contract.extraJsonData);
                        }catch (e){}

                        return <a data-toggle="modal" className="btn btn-primary btn-sm btn-block"
                                  href="#" onClick={se => {
                            this.setState({newPracticeModal: contract.id});
                        }}>Vedi Pratica {extra.codoper ? '#'+extra.codoper : ''}</a>
                    }


                })}
              </React.Fragment> }


              <p className="text-left" style={{marginTop: '20px'}}><strong>Cronologia lead</strong></p>
  
              {this.state.LeadsHistories.map( row => {
                return <div className="row" key={row.id}>
                  <div className="col-sm-8">{row.message}</div>
                  <div className="col-sm-4">{moment(row.createdAt).format('D MMM YY HH:mm')}</div>
                </div>;
              })}
  
              {this.state.newCallModal && <NewCallModal onDone={res=>{
                this.reloadData();
              }} leadId={this.state.id} />}
  
              {this.state.newContractModal && <NewContractModal onDone={res=>{
                this.reloadData();
              }} leadId={this.state.id} id={this.state.newContractModal} />}


              {this.state.newPracticeModal && <NewPracticeModal onDone={res=>{
                  this.setState({newPracticeModal: false})
                this.reloadData();
              }} leadId={this.state.id} id={this.state.newPracticeModal} />}

              {this.state.newSellModal && <EnelxSellModal onDone={res=>{
                  this.setState({newSellModal: false})
                this.reloadData();
              }} LeadId={this.state.id} id={this.state.newSellModal} />}
  
              {this.state.newAppointmentModal && <NewAppointmentModal onDone={res=>{
                this.reloadData();
              }} LeadId={this.state.id} origin='leads' />}

              {this.state.appointmentOutcomeModal && <AppointmentOutcome onDone={res=>{
                this.reloadData();
              }} id={this.state.Appointments[0].id} origin='leads' />}
              
          </div>
        </div>;
  
    }
  }

class LeadDetailHistory extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true
    };

    this.reloadData();
  }

  componentDidUpdate( prevProps ){
    if( prevProps.leadId != this.props.leadId )
      this.reloadData();
  }

  reloadData(){
    this.setState({loading: true});

    fetch( 'api' , 'Leads','get' , this.props.leadId ).then( data => {
      this.setState({...data.body,loading: false});
    })
  }

  render(){

    if( this.state.loading )
      return <Loader />;

    return <React.Fragment>
      
        <h2>Storico Lead {formatLeadName( this.state )}</h2>
      
        {this.state.LeadsHistories.map( entry => {
          
          let moreInfo = null;
          let icon = 'fa-circle';

          if( entry.OriginModelName == 'Appointments' ){
            moreInfo = <AppointmentDetailView id={entry.OriginModelId} />;
            icon = 'fa-calendar-o';
          }

          if( entry.OriginModelName == 'Calls' ){
            moreInfo = <CallsDetailView id={entry.OriginModelId} />
            icon = 'fa-phone';
          }

          if( entry.OriginModelName == 'Contracts' ){
            moreInfo = <ContractDetailView id={entry.OriginModelId} />;
            icon = 'fa-file-text';
          }

          return <div className="timeline-item" > <div className="row">
              <div className="col-sm-1 date">
                  <i className={'fa '+icon}></i>
                  {moment(entry.createdAt).format('DD MMM YY')}
                  <br />
                  {moment(entry.createdAt).format('HH:mm')}
                  <br />
                  <small className="text-navy">{moment(entry.createdAt).fromNow()}</small>
              </div>
              <div className="col-sm-11 content no-top-border" style={{paddingLeft: '15px'}}>
                  <p className="m-b-xs"><strong>{entry.message}</strong></p>

                  {entry.messageBig && <p>{entry.messageBig}</p>}

                  {entry.AuthorUser && 
                    <small class="text-muted">Eseguito da {entry.AuthorUser.name} {entry.AuthorUser.surname}</small> }

                  {moreInfo}
              </div>
          </div></div>;
        })}
      </React.Fragment>;
  }
}

class LeadsPage extends React.Component {
  render(){
    return <React.Fragment>
      <Route exact path={`${this.props.match.url}`} component={LeadsTable} />
      <Route path={`${this.props.match.url}/detail/:leadId`} component={(match) => {
        
        return <React.Fragment>
          <Breadcumbs title="Lead" items={{'Home': '/'}} />
            <div className="wrapper wrapper-content  animated fadeInRight" key="lead-table-6">
              <div className="row">
                <div className="col-sm-3">
                  <div className="ibox selected">
                    <div className="ibox-content">
                      <LeadDetail id={match.match.params.leadId} />
                    </div>
                    </div>
                </div>
                <div className="col-sm-9">
                  <div className="ibox selected">
                    <div className="ibox-content inspina-timeline">
                      <LeadDetailHistory leadId={match.match.params.leadId} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </React.Fragment>;
      }} />
    </React.Fragment>
  }
}

class LeadsTable extends React.Component {

    constructor(props){
        super(props);
        
        this.state = {
            selectedLead: false,
            showDetailHistory: false,
            filterAgentId: false,
            filterText: '',
            filterDateStart: moment().subtract( 1 , 'year' ),
            filterDateEnd: moment(),
            loadingLiberoState: true,
        };

        this.searchRef = React.createRef();
    }

    componentDidMount() {
      const filters = {
        filters: {
          parentEntityType: 'Leads',
          text: 'Libero'
        }
      };

      fetch( 'api' , 'ContractStates' , 'select' , filters ).then( msg => {
        try {
          if( msg.body.length > 0 )
            this.setState({filterState: msg.body});
        } catch (e) {
        }

        this.setState({loadingLiberoState: false});
      });
    }

    getFilters(){
      return {
        text: this.state.filterText,
        state: this.state.filterState,
        TakenByUserId: this.state.filterTakenByUserId,
        CreatedBy: this.state.filterCreatedBy,
        dateStart: this.state.filterDateStart,
        dateEnd: this.state.filterDateEnd,
        interest: this.state.filterInterest,
        AgentId: this.state.filterAgentId};
    }
  render(){

      if( this.state.loadingLiberoState ){
        return <React.Fragment>
          <Breadcumbs title="Lead" items={{'Home': '/'}} />
          <div className="wrapper wrapper-content  animated fadeInRight" key="lead-table-6">
            <Loader />
          </div>
        </React.Fragment>;
      }

        const columns = { 
            click: '',
            name: 'Nome e cognome' , 
            itnerests: 'Interessi',
            city: 'Luogo' ,
            AgentId: 'Agente',
            state: 'Stato Lead',
            contract_state: 'Stato Contratto',
            product: 'Prodotto'
        };

        const row = row => {
          let contract = null;
          let products = row.flat_UniqueContract_products ? JSON.parse( row.flat_UniqueContract_products ) : false;
          if( row.flat_Contracts_count == 1 && row.flat_UniqueContract_state ){
            let contractState = JSON.parse( row.flat_UniqueContract_state );
            if( contractState )
              contract = {
                state : contractState.name,
                ContractState: contractState
              }
          }

          return <tr key={row.id}>
                <td>{ !row.masked && <a href="#" onClick={se=>{
                    se.preventDefault(); this.setState({newLeadModal: true, editLeadId: row.id})}}>

                <i className="fa fa-pencil-square-o"></i> </a> } </td>

                <td>
                  { <a onClick={se=>{ se.preventDefault(); this.setState({selectedLead: row.id})} }
                  className="client-link">{formatLeadName( row )}</a> } </td> 

                <td><LeadInterestsPlainViewer lead={row} /> </td>

                <td>{row.city}</td>
                <td>{row.Agent ? `${row.Agent.name} ${row.Agent.surname}` : '-' }</td>
                <td className="client-status">
                <LeadState lead={row} />
                </td>
                <td>
                  {contract ?
                        <ContractStateViewer contract={contract} />
                    : `${row.flat_Contracts_count} Contratti`}
                </td>
                <td>
                  { products ? products.map( p => p.name ).join(", ") : '-'}
                </td>
            </tr>
        };

        return <React.Fragment>
            <Breadcumbs title="Lead" items={{'Home': '/'}} />
            <div className="wrapper wrapper-content  animated fadeInRight" key="lead-table-6">
            <div className="row" style={{marginBottom: '15px'}} key="lead-table-7">
                <div className="col-sm-12">
                { can('Leads.save') && <a href="#" onClick={se=>{se.preventDefault(); this.setState({newLeadModal: true, editLeadId: undefined})}} 
                  className="btn btn-primary btn-sm"> Inserisci Lead </a> }
                { can('Leads.importLeads') && <a style={{marginLeft: '10px'}} href="#" onClick={se=>{se.preventDefault(); this.setState({importLeads: true})}} 
                  className="btn btn-primary btn-sm"> Importa da file </a> }
                {can('Leads.export') && <button className="btn btn-primary btn-sm" onClick={se => {
                  se.preventDefault();
                  showExportModal( 'Leads' , this.getFilters() );
                }} style={{marginLeft: '10px'}}>Esporta Excel</button>}
                </div>

            </div>

            {this.state.newLeadModal && <NewLeadModal id={this.state.editLeadId} onDone={se=>{this.setState({newLeadModal: false})}} />}
            {this.state.importLeads && <ImportLeadModal onDone={se=>{this.setState({importLeads: false})}} />}

            <div className="row" key="lead-table-8">
              <div className="col-sm-9" key="lead-table-9">

                    <div className="ibox">
                        <div className="ibox-content">

                        {this.state.info && [ <span className="float-right small text-muted">{this.state.info.count} Contatti </span>]}

                        <h2>Contatti</h2>

                            <div className="input-group" key="lead-table-5">
                                <div className="col-sm-3 m-b-xs" key="lead-table-4">

                                    <LeadStateSelect label="Esito del Lead" value={this.state.filterState}
                                      all onChange={se=>this.setState({filterState: se})} />
                                
                                </div>

                              { can( 'Users.filterTakenBy' ) &&  <div className="col-sm-4 m-b-xs" key="lead-table-3">
                                    <UserSelect label="Preso in carico da"
                                        all={true}
                                        value={this.state.filterTakenBy}
                                        direction={FormInputContainer.HORIZONTAL}
              onChange={se=>this.setState({filterTakenBy: se,filterTakenByUserId: se ? se.id : null})} />
                                </div> }

                                <div className="col-sm-4 m-b-xs" key="lead-table-2">
                                  <FormInputText label="Cerca per cognome" ref={this.searchRef}
                                    direction={FormInputContainer.HORIZONTAL} />
                                </div>

                                <div className="col-sm-1 m-b-xs">
                                  <button className="btn btn-default " style={{marginTop: '2em'}}
                                    onClick={se=>{this.setState({filterText: this.searchRef.current.inputRef.current.value})}}>
                                      <i className="fa fa-search"></i></button>
                                </div>

                                <div className="col-lg-3">
                                    <FormInputDate value={this.state.filterDateStart}
                                    key="sdate"
                                    label="Data creazione / Da"
                                    direction={FormInputContainer.HORIZONTAL}
                                    onChange={se=>{this.setState({filterDateStart: se}) }} />
                                </div>

                                <div className="col-lg-3">
                                    <FormInputDate value={this.state.filterDateEnd}
                                    key="edate"
                                    label="Data creazione / a"
                                    direction={FormInputContainer.HORIZONTAL}
                                    onChange={se=>{this.setState({filterDateEnd: se}) }} />
                                </div>

                              { can( 'Leads.filterCreatedBy' ) && <div className="col-sm-4 m-b-xs" key="lead-table-3">
                                   <UserSelect label="Caricato da"
                                     isMulti
                                     value={this.state.filterCreatedBy}
                                     direction={FormInputContainer.HORIZONTAL}
                                     onChange={se=>this.setState({filterCreatedBy: se})} />
                                </div> }

                              { can( 'Leads.filterInterests' ) &&<div className="col-lg-4">
                                  <LeadInterestSelect value={this.state.filterInterest}
                                    onChange={se=>{this.setState({filterInterest: se})}}
                                    direction={FormInputContainer.HORIZONTAL} />
                              </div>}

                            </div>

                            <div className="">
                                <AbstractTableViewer model="Leads"
                                    filters={this.getFilters()}
                                    row={row}
                                    columns={columns}/>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-sm-3" key="lead-table-10">
                <div className="ibox selected">
                    <div className="ibox-content">
                        <div className="tab-content">
                            <div id="" className="tab-pane active">
                            { !this.state.selectedLead ? <p style={{minHeight: '20em', textAlign: 'center', margin: '20px'}}>Seleziona un lead per visualizzare i dettagli</p> : 
                                <LeadDetail id={this.state.selectedLead} />}

                            { this.state.selectedLead && !this.state.showDetailHistory &&
                              <Link className="btn btn-primary" to={`${window.location.pathname}/detail/${this.state.selectedLead}`}>
                                Mostra dettagli storico</Link> }

                            { this.state.selectedLead && this.state.showDetailHistory &&
                              <button className="btn btn-primary" 
                                onClick={se=>{this.setState({showDetailHistory: false})}}>
                                Chiudi dettagli</button> }

                            </div>
                        </div>
                    </div>
                        
                </div>
              </div>
                              
            </div>
        </div>
      </React.Fragment>;
    }

}

class ImportLeadModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      step: 'import',
    };

    this.onLoadFiles = this.onLoadFiles.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onLoadFiles(se){
    se.preventDefault();

    this.setState({step: 'loading'});

    fetch( 'api' , 'Leads' , 'importPreview' , this.state ).then( data => {
      this.setState({step: 'save', results: data.body});
    });

  }

  onSave(se){

    this.setState({step: 'loading'});

    fetch( 'api' , 'Leads' , 'importSave' , this.state ).then( data => {
      if( this.props.onDone ) this.props.onDone();
    });

  }

  render(){
    return <Modal onClose={this.props.onDone}>
        <form onSubmit={this.onSubmit}>
          

          {this.state.step == 'loading' && <Loader />}
          
          {this.state.step == 'import' && <React.Fragment>

            <h2>Importa leads da file</h2>

            <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
              filesId={this.state.filesId} />

            <FormButtons saveLabel="Carica file"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onLoadFiles} 
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </React.Fragment>}

          {this.state.step == 'save' && <React.Fragment>

            <h2>Preview di inserimento:</h2>

            {this.state.results.map( lead => {
              return <React.Fragment>
                <p><b>Nome: </b>{lead.name}</p>
                <p><b>Cognome: </b>{lead.surname}</p>
                <p><b>Telefono: </b>{lead.telephone}</p>
                <p><b>Interesse: </b>{lead.interest}</p>
                <FormDashline />
              </React.Fragment>
            })}

            <FormButtons saveLabel="Salva leads"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSave} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </React.Fragment>}

        </form>
      </Modal>;
  }
}

function formatLeadName( leadObject ){
  try {

    let result = `${leadObject.name} ${leadObject.surname}`;

    if( leadObject.businessName ){
      if( leadObject.businessName.length > 0 ){
        result = leadObject.businessName;

        if( leadObject.name || leadObject.surname ){
          result = `${leadObject.businessName} (${leadObject.name || ''} ${leadObject.surname || ''})`;
        }

      }
    }

    return result;

  } catch(e){}

  return '-';
}

export { LeadsTable, AddressData, ImportLeadModal , formatLeadName , LeadsPage };
