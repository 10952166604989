import React from 'react';
import './App.css';
import moment from 'moment';

import {
    Loader, FormDashline, fetch,
    FormInputText, FormButtons, FormInputContainer,
    Modal, BoxContainer, FormInputDate, FormInputSelect
} from './Common.js';

import {
    AbstractTableViewer, can, AbstractApiFormFooter,
    apiFormInit, apiFormSave,
    ApiMultiSelect, Breadcumbs, FileUploadInput
} from './AppCommon';
import {UserSelect} from "./Users";
import {EvaluationFormStateSelect} from "./EvaluationFormStates";
import {SellingPointSelect} from "./SellingPoints";

const defaultChannels = {
    "Social":"Social",
    "Volantini":"Volantini",
    "Vetrine":"Vetrine",
    "Ricerca su Google":"Ricerca su Google",
    "Passaparola":"Passaparola",
    "Istituzionale Enel":"Istituzionale Enel",
    "Storico (Problematica Pratica Recente)":"Storico (Problematica Pratica Recente)",
    "Storico (Verifica Fattura Alta)":"Storico (Verifica Fattura Alta)",
    "Storico (Nuova Operazione)":"Storico (Nuova Operazione)",
    "Storico (Informazione)":"Storico (Informazione)",
    "Pagamento Pos":"Pagamento Pos"
};

const renderChannels = (SellingPoint) => {
    let channels = {...defaultChannels};

    if (SellingPoint && SellingPoint.TagsChannel ){
        let customs = Object.fromEntries( SellingPoint.TagsChannel.map( channel => {
            return [ channel.name , channel.name ];
        }));

        channels = {...channels, ...customs};
    }

    return channels;
}

const renderCities = (SellingPoint) => {
    if( !SellingPoint || !SellingPoint.TagsCity )
        return {};

    return Object.fromEntries( SellingPoint.TagsCity.map( city => {
        return [ city.name , city.name ];
    }));
}

class Pedonabilities extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pedonabilitiesModal: false,
            pedonabilities: false,

            /*            filterNumber: null,
                        filterList: null,
                        filterDateStart: moment().startOf('year'),
                        filterDateEnd: moment().endOf('year').add('1','year'),*/
        };

    }

    componentDidMount() {
/*        fetch('api', 'Pedonabilities', 'select', {filters: {}}).then(msg => {
            this.setState({pedonabilities: msg.body});
        });*/
    }



    getFilters() {

        return {};
    }


    render() {

        const columns = {
            user: 'Consulente',
            sellingPoint: 'SEP',
            channel: 'Canale',
            city: 'Città di provenienza',
            actions: 'Azioni'
        };

        const row = p => {
            return <tr>
                <td> { p.User && <>{p.User.name} {p.User.surname}</>}</td>
                <td>{p.SellingPoint && p.SellingPoint.name}</td>
                <td>{p.channel}</td>
                <td>{p.city}</td>
                <td>
                    <div class="btn-group">
                        {can('Pedonability.save') && <button class="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({pedonabilitiesModal: p.id});
                        }}>Vedi</button>}
                    </div>
                </td>
            </tr>
        };

        return [<Breadcumbs title="Pedonabilità" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">
           {/* <div className="row" style={{marginBottom: '15px'}}>
                <div className={'col-12'}>
                    {can('TelephonesWhitelist.save') && <button className="btn btn-primary" onClick={se => {
                        se.preventDefault();
                        this.setState({pedonabilitiesModal: true});
                    }}>Compila Modulo</button>}
                </div>
            </div>*/}


            {can('Pedonability.save') &&
            <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="row">

                                <NewPedonabilitiesForm/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            <div className="row">

                <div className="col-12">
                    {this.state.loading ? <Loader/> :
                        <div className="ibox">
                            <div className="ibox-content">
                                <AbstractTableViewer model="Pedonabilities"
                                                     filters={this.getFilters()}
                                                     row={row}
                                                     columns={columns}/>

                            </div>
                        </div>}
                </div>


            </div>

            {this.state.pedonabilitiesModal && <NewPedonabilitiesModal onDone={res => {
                this.setState({pedonabilitiesModal: false});
            }} id={this.state.pedonabilitiesModal === true ? false : this.state.pedonabilitiesModal}/>}


        </div>];
    }
}

class NewPedonabilitiesForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            /*SellingPoint: {
                id: 1,
                name: 'Acquaviva delle fonti'
            },
            SellingPointId: 1,*/
            channel: 'Social',
            //city: 'Acquaviva,70021',

        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        //apiFormInit('Pedonabilities', this);
    }

    onSubmit(se) {
        se.preventDefault();
        apiFormSave('Pedonabilities', this).then(()=>{
                window.location.reload();
        });
    }

    render() {



        return <form className={'form-row w-100'} onSubmit={this.onSubmit}>

               <div className={'form-group col-12 col-md-3'}>

                <SellingPointSelect value={this.state.SellingPoint}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="SEP"
                                    onChange={se => this.setState({SellingPoint: se, SellingPointId: se ? se.id : null})}/>

               </div>

            <div className={'form-group col-12 col-md-3'}>

                <FormInputSelect label="Canale" value={this.state.channel}
                                 values={renderChannels(this.state.SellingPoint)}
                                 direction={FormInputContainer.HORIZONTAL}
                                 validation={this.state.validation} name="channel"
                                 onChange={se => this.setState({channel: se})}/>

            </div>

            <div className={'form-group col-12 col-md-3'}>

                <FormInputSelect label="Città di provenienza" value={this.state.city}
                                 values={renderCities(this.state.SellingPoint)}
                                 direction={FormInputContainer.HORIZONTAL}
                                 validation={this.state.validation} name="city"
                                 onChange={se => this.setState({city: se})}/>

            </div>

            <div className={'form-group col-12 col-md-3 text-right d-flex align-items-center justify-content-end pt-3'}>


                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Inserisci" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </div>

            </form>
    }

}


class NewPedonabilitiesModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            channel: 'Social',
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        apiFormInit('Pedonabilities', this);
    }

    onSubmit(se) {
        se.preventDefault();
        apiFormSave('Pedonabilities', this);
    }

    render() {



        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                {this.state.User && <div className={'my-2'}>
                    <p>Consulente: <b>{this.state.User.name} {this.state.User.surname}</b></p>
                </div>}

                <SellingPointSelect value={this.state.SellingPoint}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="SEP"
                                    onChange={se => this.setState({SellingPoint: se, SellingPointId: se ? se.id : null})}/>

                <FormInputSelect label="Canale" value={this.state.channel}
                                 values={renderChannels(this.state.SellingPoint)}
                                 direction={FormInputContainer.HORIZONTAL}
                                 validation={this.state.validation} name="channel"
                                 onChange={se => this.setState({channel: se})}/>


                <FormInputSelect label="Città di provenienza" value={this.state.city}
                                 values={renderCities(this.state.SellingPoint)}
                                 direction={FormInputContainer.HORIZONTAL}
                                 validation={this.state.validation} name="city"
                                 onChange={se => this.setState({city: se})}/>


                <FormDashline/>

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </form>
        </Modal>;
    }

}

export {Pedonabilities, NewPedonabilitiesModal, NewPedonabilitiesForm}